import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { MuiTelInput } from 'mui-tel-input';

// ----------------------------------------------------------------------

RHFPhoneNumber.propTypes = {
    name: PropTypes.string,
    helperText: PropTypes.node,
};

export default function RHFPhoneNumber({ name, helperText, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <MuiTelInput
                    {...field}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    variant="outlined"
                    fullWidth
                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    {...other}
                />
            )}
        />
    );
}