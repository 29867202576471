// icons
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import BallotIcon from '@mui/icons-material/Ballot';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BadgeIcon from '@mui/icons-material/Badge';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const profileIcon = () => (
    <PersonIcon />
)

const profilesIcon = () => (
    <PeopleIcon />
)

const rolesIcon = () => (
    <AdminPanelSettingsIcon />
)

const publicIcon = () => (
    <PublicIcon />
)

const policeIcon = () => (
    <LocalPoliceIcon />
)

const voteIcon = () => (
    <HowToVoteIcon />
)

const ballotIcon = () => (
    <BallotIcon />
)

const membershipIcon = () => (
    <CardMembershipIcon />
)

const paidMembershipAdminIcon = () => (
    <LoyaltyIcon />
)

const idCardIcon = () => (
    <BadgeIcon />
)

const ICONS = {
  profile: profileIcon(),
  public: publicIcon(),
  profiles: profilesIcon(),
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  grammarly: icon('ic_grammarly'),
  role: rolesIcon(),
  superAdmin: policeIcon(),
  vote: voteIcon(),
  ballot: ballotIcon(),
  membership: membershipIcon(),
  paidMembershipAdmin: paidMembershipAdminIcon(),
  idCard: idCardIcon(),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Profiles',
    items: [
      { title: 'Own Profile', path: PATH_DASHBOARD.profile.me, icon: ICONS.profile},
      { title: 'Set Public Fields', path: PATH_DASHBOARD.profile.myPublicFields, icon: ICONS.public},
      { title: 'Manage Membership', path: PATH_DASHBOARD.profile.membershipManagement, icon: ICONS.membership},
      { title: 'ID Card', path: PATH_DASHBOARD.profile.idCard, icon: ICONS.idCard},
      // { title: 'All Profiles', path: PATH_DASHBOARD.profile.all, icon: ICONS.profiles},
      // { title: 'All Profiles', path: PATH_DASHBOARD.profile.all2, icon: ICONS.profiles},
      // { title: 'One', path: PATH_DASHBOARD.one, icon: ICONS.dashboard },
      // { title: 'Two', path: PATH_DASHBOARD.two, icon: ICONS.ecommerce },
      // { title: 'Three', path: PATH_DASHBOARD.three, icon: ICONS.analytics },
    ],
  },
  {
    subheader: 'Election',
    items: [
      // { title: 'Vote', path: PATH_DASHBOARD.election.vote, icon: ICONS.vote},
      { title: 'Result', path: PATH_DASHBOARD.election.voteResult, icon: ICONS.ballot, roles: ['electionAdmin']},
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Admin',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },

  // CENSUS
  // ----------------------------------------------------------------------
  {
    subheader: 'Others',
    // roles: ['admin'],
    items: [
      { title: 'Grammarly', path: PATH_DASHBOARD.facilities.grammarly, icon: ICONS.grammarly},
      { title: 'Census Admin', path: PATH_DASHBOARD.census.admin, icon: ICONS.profile, roles: ['censusAdmin', 'superAdmin'] },
      { title: 'Role Management', path: PATH_DASHBOARD.admin.roleManagement, icon: ICONS.role, roles: ['superAdmin']},
      { title: 'Super Admin', path: PATH_DASHBOARD.admin.superAdmin, icon: ICONS.superAdmin, roles: ['superAdmin'] },
      { title: 'Paid Memberships Admin', path: PATH_DASHBOARD.admin.paidMembershipAdmin, icon: ICONS.paidMembershipAdmin, roles: ['superAdmin', 'entrepreneurship'] },
    ],
  },

];

export default navConfig;
