import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import {MaterialReactTable} from 'material-react-table';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from "@mui/material/styles";
import {LoadingButton} from "@mui/lab";


import axios from "../../../utils/axios";
import {useSettingsContext} from "../../../components/settings";
import {useSnackbar} from "../../../components/snackbar";
import {useLocales} from "../../../locales";
import useResponsive from "../../../hooks/useResponsive";


const StyledButton = styled(Button)(({ presetsColor }) => ({
    color: presetsColor.contrastText,
    backgroundColor: presetsColor.main,
    borderColor: presetsColor.dark,
    "&:hover": {
        backgroundColor: presetsColor.dark,
        borderColor: presetsColor.dark,
    }
}));

const StyledInput = styled(TextField)(({ presetsColor }) => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 0,
            borderColor: presetsColor.main,
        },
        "&:hover fieldset": {
            borderColor: presetsColor.lighter,
        },
        "&.Mui-focused fieldset": {
            borderColor: presetsColor.main,
        },
        "& input": {
            textAlign: "center",
            width: 60,
        }
    }
}));

function VoteCounter({ candidateName, maxVotes, onVote, availableVotes }) {
    const { presetsColor } = useSettingsContext();
    const [count, setCount] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const handleIncrease = () => {
        if (count < maxVotes && availableVotes > 0) {
            setCount(prevCount => prevCount + 1);
            onVote(candidateName, 1);
        } else {
            // Show alert
            enqueueSnackbar("You don't have enough available voting quota!", { variant: 'error' });
        }

    };

    const handleDecrease = () => {
        if (count > 0) {
            setCount(prevCount => prevCount - 1);
            onVote(candidateName, -1);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonGroup>
                <StyledButton presetsColor={presetsColor} onClick={handleDecrease}>
                    <RemoveIcon />
                </StyledButton>
                <StyledInput presetsColor={presetsColor} size="small" value={count} />
                <StyledButton presetsColor={presetsColor} onClick={handleIncrease}>
                    <AddIcon />
                </StyledButton>
            </ButtonGroup>

        </div>
    );
}

const SummaryDialog = (props) => {
    const { onAgree, onDisagree, open, votes } = props;
    const { translate } = useLocales();

    const handleDisagree = () => {
        onDisagree();
    };

    const handleAgree = () => {
        onAgree();
    };

    return (
        <Dialog
            open={open}
            onClose={handleDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {translate("voteSummaryDialog.title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {translate("voteSummaryDialog.description")}
                    <br/>
                    <br/>
                    {Object.entries(votes).map(([candidateName, count]) => (
                        <Typography variant="body1">
                            {candidateName}: {count} vote(s)
                        </Typography>
                    ))}
                    <br />
                    <br />
                    {translate("voteSummaryDialog.warning")}

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDisagree}>{translate("buttons.disagree")}</Button>
                <LoadingButton type="submit" onClick={handleAgree} autoFocus>
                    {translate("buttons.agree")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}


export default function VotingSelector({ user }) {
    const navigate = useNavigate();
    const isDesktop = useResponsive('up', 'md');
    const { enqueueSnackbar } = useSnackbar();
    const { translate } = useLocales();

    const [candidates, setCandidates] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState(null);

    let MAX_TOTAL_VOTES = null;
    if (user.voterType === "chartered" || user.voterType === "yorePremium") {
        MAX_TOTAL_VOTES = 3;
    } else if (user.voterType === "affiliate" || user.voterType === "yore") {
        MAX_TOTAL_VOTES = 1;
    }

    const [totalVotesCast, setTotalVotesCast] = useState(user.hasVoted);
    const [votes, setVotes] = useState({});


    useEffect(() => {
        axios.get(`/api/election/candidates`).then((response) => {
            setCandidates(response.data.data);
        });
    }, []);

    useEffect(() => {
        setTotalVotesCast(user.hasVoted);
    }, [user.hasVoted]);

    useEffect(() => {
        if (isDesktop) {
            setColumnVisibility({
                name: true,
                university: true,
                role: true,
            });
        } else {
            setColumnVisibility({
                name: true,
                university: false,
                role: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop]);

    // show modal before submit voting
    const [showSummaryDialog, setShowSummaryDialog] = useState(false);

    const handleVote = () => {
        if (Object.keys(votes).length === 0) {
            enqueueSnackbar("You haven't selected any candidate(s)!", { variant: 'error' });
        } else {
            setShowSummaryDialog(true);
        }
    }

    const handleCloseSummaryDialog = (value) => {
        setShowSummaryDialog(false);
    };

    const handleElectionClick = async () => {
        // TODO: if vote is 0, show alert
        setShowSummaryDialog(false);
        console.log(votes);
        await axios.post(`/api/election/vote`, {
            voter: user.email,
            voterType: user.voterType,
            candidates: votes,
        }).then((response) => {
            if (response.data.status) {
                // refresh page
                window.location.reload();
                console.log("Success")
            } else {
                // Show alert
                console.log("ERROR");
            }
        });
    }

    const handleVoteChange = (candidate, change) => {
        setTotalVotesCast(prevVotes => prevVotes + change);
        // Here, you can also handle side effects such as saving the vote to a backend server.
        setVotes(prevVotes => {
            const newVotes = {...prevVotes};
            newVotes[candidate] = (newVotes[candidate] || 0) + change;
            if (newVotes[candidate] === 0) {
                delete newVotes[candidate];
            }
            return newVotes;
        });
    };

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setColumnVisibility({
                name: true,
                university: true,
                role: true,
            });
        } else {
            setColumnVisibility({
                name: true,
                university: false,
                role: false,
            });
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: translate("vote.name"),
                size: 150,
            },
            {
                accessorKey: 'university', //normal accessorKey
                header: translate("vote.university"),
                size: 150,
            },
            {
                accessorKey: 'role',
                header: translate("vote.role"),
                size: 200,
            }
        ],
        [translate],
    );

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>
                <Typography>Hi, {user.name}!</Typography>
            </Box>
            {user.hasVoted >= MAX_TOTAL_VOTES &&
                <Typography variant="h4" textAlign="center" mb={5}>
                    {translate("vote.thanks")}
                </Typography>
            }
            <Typography variant="h4" textAlign="center" sx={{ m: 3 }}>
                {translate("vote.end")}
            </Typography>
            {/*{user.hasVoted >= MAX_TOTAL_VOTES ?*/}
            {/*    <Typography variant="h4" textAlign="center" mb={5}>*/}
            {/*        {translate("vote.thanks")}*/}
            {/*    </Typography>*/}
            {/*    :*/}
            {/*    <Box sx={{ m: 3 }}>*/}
            {/*        <Typography>{translate("vote.blurb1")}</Typography>*/}
            {/*        <br />*/}
            {/*        <Typography>{translate("vote.blurb2")}</Typography>*/}
            {/*        <br />*/}
            {/*        <Typography>{translate("vote.blurb3")}</Typography>*/}
            {/*        <br />*/}
            {/*        {(user.voterType === "chartered" || user.voterType === "yorePremium")&& (*/}
            {/*            <Typography>{translate("vote.chartered")}</Typography>*/}
            {/*        )}*/}
            {/*    </Box>*/}
            {/*}*/}
            {/*<Typography variant="h4" sx={{ m: 3 }}>*/}
            {/*    {translate("vote.totalNumCandidates")}: {candidates.length}*/}
            {/*</Typography>*/}
            {/*<Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>*/}
            {/*    <Typography variant="h5">*/}
            {/*        {translate("vote.maxVotes")}: {MAX_TOTAL_VOTES}*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="h5">*/}
            {/*        {translate("vote.usedVotes")}: {user.hasVoted}*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="h5">*/}
            {/*        {translate("vote.availableVotes")}: {MAX_TOTAL_VOTES - totalVotesCast}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*{user.hasVoted >= MAX_TOTAL_VOTES ?*/}
            {/*    <Typography variant="h4" textAlign="center" mb={5}>*/}
            {/*        {translate("vote.alreadyVoted")}*/}
            {/*    </Typography>*/}
            {/* :*/}
            {/*    <>*/}
            {/*        <Box>*/}
            {/*            <MaterialReactTable*/}
            {/*                columns={columns}*/}
            {/*                data={candidates}*/}
            {/*                enablePagination={false}*/}
            {/*                enableTopToolbar*/}
            {/*                renderTopToolbarCustomActions={({ table }) => (*/}
            {/*                    <FormControlLabel control={<Checkbox defaultChecked={isDesktop} onChange={handleCheckboxChange}/>} label={translate("vote.checkbox")} />*/}
            {/*                )}*/}
            {/*                enableTableHead*/}
            {/*                enableGlobalFilter={false} //turn off a feature*/}
            {/*                muiTableHeadCellProps={{*/}
            {/*                    //simple styling with the `sx` prop, works just like a style prop in this example*/}
            {/*                    sx: {*/}
            {/*                        backgroundColor: (theme) => theme.palette.grey[200],*/}
            {/*                        borderRight: '2px solid #e0e0e0', //add a border between columns*/}
            {/*                    },*/}
            {/*                }}*/}
            {/*                muiTableHeadCellFilterTextFieldProps={{*/}
            {/*                    placeholder: 'Search',*/}
            {/*                }}*/}
            {/*                enableRowActions*/}
            {/*                positionActionsColumn="last"*/}
            {/*                displayColumnDefOptions={{*/}
            {/*                    'mrt-row-actions': {*/}
            {/*                        header: 'Vote', //change header text*/}
            {/*                        size: 200, //make actions column wider*/}
            {/*                    },*/}
            {/*                }}*/}
            {/*                initialState={{ columnVisibility, showColumnFilters: true }}*/}
            {/*                state={{ columnVisibility }}*/}
            {/*                renderRowActions={({ row, table }) => (*/}
            {/*                    <VoteCounter*/}
            {/*                        candidateName={`${row.original.name}`}*/}
            {/*                        maxVotes={MAX_TOTAL_VOTES}*/}
            {/*                        onVote={handleVoteChange}*/}
            {/*                        availableVotes={MAX_TOTAL_VOTES - totalVotesCast}*/}
            {/*                    />*/}
            {/*                )}*/}
            {/*                enableBottomToolbar*/}
            {/*                renderBottomToolbarCustomActions={({ table }) => (*/}
            {/*                    <Button*/}
            {/*                        variant="contained"*/}
            {/*                        color="primary"*/}
            {/*                        onClick={handleVote}*/}
            {/*                    >*/}
            {/*                        {translate("buttons.submitVotes")}*/}
            {/*                    </Button>*/}
            {/*                )}*/}
            {/*                muiBottomToolbarProps={{*/}
            {/*                    sx: {*/}
            {/*                        //make button aligned with vote counter (last column)*/}
            {/*                        justifyContent: 'center'*/}

            {/*                    }*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Box>*/}
            {/*    </>*/}
            {/*}*/}
            {/*<SummaryDialog*/}
            {/*    votes={votes}*/}
            {/*    open={showSummaryDialog}*/}
            {/*    onAgree={handleElectionClick}*/}
            {/*    onDisagree={handleCloseSummaryDialog}*/}
            {/*/>*/}
        </>
    );


}
