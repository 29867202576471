import PropTypes from 'prop-types';
import {Link as RouterLink} from "react-router-dom";
// @mui
import { useTheme } from '@mui/material/styles';
import { Link, Stack, AppBar, Toolbar, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../utils/cssStyles';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import { useSettingsContext } from '../../components/settings';
//
import LanguagePopover from '../dashboard/header/LanguagePopover';
import {StyledItem} from "../../components/nav-section/mini/styles";
import {StyledSubheader} from "../../components/nav-section/vertical/styles";


// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const renderContent = (
    <>


        <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        // spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <Logo/>
        <Link component={RouterLink} to="/" color="text.primary" style={{'textDecoration': 'none'}}>
            <Typography variant="h3" color="text.primary">
                Portal Doctrine UK
            </Typography>
        </Link>
        <LanguagePopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100%)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
