import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
// config
import { HOST_API_KEY } from '../config-global';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
    isVerified: false,
};

const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
            isVerified: action.payload.user?.verified,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
            isVerified: action.payload.user?.verified,
        };
    }
    if (action.type === 'REGISTER') {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }

    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const storageAvailable = localStorageAvailable();

    const initialize = useCallback(async () => {
        try {
            const res = await axios.get('/api/auth/login/success', {withCredentials: true});
            setSession(res.data.user.accessToken);
            localStorage.setItem("refreshToken", res.data.user.refreshToken);
            localStorage.setItem("userId", res.data.user.id)
            dispatch({
                type: 'INITIAL',
                payload: {
                    isAuthenticated: res.data.isAuthenticated,
                    user: res.data.user,
                },
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: 'INITIAL',
                payload: {
                    isAuthenticated: false,
                    user: null,
                    verified: false,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = useCallback(async () => {
        window.open(`${HOST_API_KEY}/api/auth/login/google`, "_self");
        // const response = await axios.get('/api/auth/login/google');
        // const { accessToken, user } = response.data;
        //
        // setSession(accessToken);
        //
        // dispatch({
        //     type: 'LOGIN',
        //     payload: {
        //         user,
        //     },
        // });
    }, []);

    // REGISTER
    // const register = useCallback(async (email, password, firstName, lastName) => {
    //     const response = await axios.post('/api/account/register', {
    //         email,
    //         password,
    //         firstName,
    //         lastName,
    //     });
    //     const { accessToken, user } = response.data;
    //
    //     localStorage.setItem('accessToken', accessToken);
    //
    //     dispatch({
    //         type: 'REGISTER',
    //         payload: {
    //             user,
    //         },
    //     });
    // }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        // console.log(localStorage.getItem("refreshToken"))
        const response = await axios.post('/api/auth/logout',
            {
                id: localStorage.getItem("userId"),
                refreshToken: localStorage.getItem("refreshToken")
            });
        // console.log(response)
        setSession(null);
        localStorage.setItem("refreshToken", null);
        dispatch({
            type: 'LOGOUT',
        });
    }, []);

    const memoizedValue = useMemo(
        () => ({
            isInitialized: state.isInitialized,
            isAuthenticated: state.isAuthenticated,
            isVerified: state.user?.verified,
            user: state.user,
            method: 'google',
            login,
            // register,
            logout,
        }),
        // [state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
        [state.isAuthenticated, state.isInitialized, state.user, login, logout]

    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
