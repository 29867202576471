import {useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Box, Button, Card, Container, Skeleton, Typography} from "@mui/material";
import {MaterialReactTable} from 'material-react-table';


import axios from "../../utils/axios";
import {useSettingsContext} from "../../components/settings";
import {PATH_DASHBOARD} from "../../routes/paths";
import VotingSelector from "../../sections/@dashboard/election/VotingSelector";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {useAuthContext} from "../../auth/useAuthContext";
import RoleBasedGuard from "../../auth/RoleBasedGuard";


export default function ElectionResultPage() {
    const { themeStretch } = useSettingsContext();
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const [voteResult, setVoteResult] = useState([]);

    useEffect(() => {
        axios.get(`/api/election/voteResult`, {withCredentials: true}).then((response) => {
            setVoteResult(response.data.data);
        }).catch((error) => {
            if (error.status === 404) {
                navigate('/404');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                size: 150,
            },
            {
                accessorKey: 'votedBy', //normal accessorKey
                header: 'Number of Votes',
                size: 150,
            },
        ],
        [],
    );

    const onDownload = async () => {
        axios.get('/api/election/exportCsv', {'responseType': 'blob', withCredentials: true}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'election.csv');
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <>
            <Helmet>
                <title> Election Result | Doctrine UK</title>
            </Helmet>

            <RoleBasedGuard hasContent roles={['electionAdmin']}>
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <CustomBreadcrumbs
                        heading="Election Result"
                        links={[
                            { name: 'Dashboard', href: PATH_DASHBOARD.root },
                            { name: 'Election Result' },
                        ]}
                    />
                    <Card>
                        <Button variant="contained" onClick={onDownload}>
                            Download as CSV
                        </Button>
                        <MaterialReactTable
                            columns={columns}
                            data={voteResult}
                            enableTableHead
                            enableGlobalFilter={false} //turn off a feature
                            muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                    backgroundColor: (theme) => theme.palette.grey[200],
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                },
                            }}
                            initialState={{
                                sorting: [
                                    { id: 'votedBy', desc: true },
                                ],
                            }}
                        />
                    </Card>
                </Container>
            </RoleBasedGuard>
        </>
    );
}
