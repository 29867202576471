// social login buttons
import { GoogleLoginButton } from "react-social-login-buttons";
// @mui
import { Divider, IconButton, Stack } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------
export default function AuthWithSocial() {
  const { login } = useAuthContext();

  const handleGoogleLogin = async () => {
    try {
      if (login) {
        login();
      }
      // console.log('GOOGLE LOGIN');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <GoogleLoginButton onClick={handleGoogleLogin} />
    </div>
  );
}
