// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile/me/'),
    me: path(ROOTS_DASHBOARD, '/profile/me/'),
    myPublicFields: path(ROOTS_DASHBOARD, '/profile/public-fields'),
    membershipManagement: path(ROOTS_DASHBOARD, '/profile/membership-management'),
    all: path(ROOTS_DASHBOARD, '/profile/all'),
    all2: path(ROOTS_DASHBOARD, '/profile/all2'),
    idCard: path(ROOTS_DASHBOARD, '/profile/id-card'),
  },
  census: {
    root: path('/census'),
    comingSoon: path('/census/coming-soon'),
    new: path( '/census', 'new'),
    thanks: (id) => path( '/census', `/${id}/thanks`),
    chartered: (id) => path( '/census', `/${id}/chartered`),
    charteredThanks: (id) => path( '/census', `/${id}/chartered/thanks`),
    yorePremium: (id) => path( '/census', `/${id}/yore-premium`),
    yorePremiumThanks: (id) => path( '/census', `/${id}/yore-premium/thanks`),
    admin: path(ROOTS_DASHBOARD, '/census/admin'),
    profile: (id) => path(ROOTS_DASHBOARD, `/census/${id}`),
    emailCreated: (id) => path( `/census`, `/${id}/email-created`),
  },
  election: {
    root: path(ROOTS_DASHBOARD, '/election'),
    vote: path(ROOTS_DASHBOARD, '/election/vote'),
    votePublic: (id) => path(`/election/vote/${id}`),
    voteLanding: path('/election'),
    voteResult: path(ROOTS_DASHBOARD, '/election/result'),
  },
  facilities: {
    root: path(ROOTS_DASHBOARD, '/facilities'),
    grammarly: path(ROOTS_DASHBOARD, '/facilities/grammarly'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    roleManagement: path(ROOTS_DASHBOARD, '/admin/roles'),
    superAdmin: path(ROOTS_DASHBOARD, '/admin/super-admin'),
    paidMembershipAdmin: path(ROOTS_DASHBOARD, '/admin/paid-member-admin'),
  },
  // ownProfile: path(ROOTS_DASHBOARD, '/own-profile'),
  // allProfiles: path(ROOTS_DASHBOARD, '/all-profiles'),
  one: path(ROOTS_DASHBOARD, '/one'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
