// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  profileFieldsGroup: {
    basicInformation: 'Basic Information',
    phdRelatedInformation: 'PhD-related Information',
    affiliation: 'Affiliation',
    socialMedia: 'Social Media',
    publications: 'Scientific Publications',
    popularScienceArticles: 'Popular Science Articles',
    communityServices: 'Community Services',
    speakerExperiences: 'Speaker Experiences',
    yorePremium: 'Additional Questions for Member of Yore Premium',
    profilePicture: 'Profile Picture',
    paymentProof: 'Membership Proof of Payment',
    otherInformation: 'Other Information',
  },
  profileFields: {
    membershipType: 'Membership Type',
    fullName: 'Full Name',
    givenName: 'Given Name',
    familyName: 'Family Name',
    nickname: 'Nickname',
    gender: 'Gender',
    hasDisability: 'Do you consider yourself to have a disability?',
    disability: 'Disability Type',
    emailAddress: 'Email Address',
    emailAddressHelperText: 'Personal email address',
    doctrineEmailPreExisted: 'Do you already have a Doctrine email address?',
    doctrineEmailAddress: 'Doctrine Email Address',
    doctrineEmailAddressCreated: 'Has the Doctrine email address been created?',
    doctrineEmailAddressHelperTextNotExist: 'The desired Doctrine email address.',
    doctrineEmailAddressHelperTextExisted: 'Your current Doctrine email address.',
    secondaryEmailAddress: 'Secondary Email Address',
    phoneNumber: 'Whatsapp Number (for joining Doctrine UK WA group)',
    phoneNumberHelperText: 'Phone number that is used for WhatsApp. You can change the country code by clicking the flag icon.',
    region: 'Region',
    regionHelperText: 'Region based on your current residence or your university in the UK. Click the button to see the map for reference.',
    status: 'PhD Status',
    statusHelperText: 'Your status related to the UK.',
    UKInstitution: 'UK Institution',
    university: 'University (PhD)',
    universityHelperText: 'University where you are doing or did your PhD. ' +
        'If it is not in the list, please choose "Other" and you can type the name of the university in the other field.',
    otherUniversity: 'Other University (PhD)',
    programmeName: 'PhD Programme Name (Major)',
    academicEmailAddress: 'Academic Email Address',
    universityProfileURL: 'University Profile URL',
    universityProfileURLHelperText: 'For example: https://www.ucl.ac.uk/bartlett/construction/people/phd-students/gatot-subroto',
    phdStartDate: 'PhD Start Date',
    phdEndDate: 'PhD End Date',
    fundingSource: 'Funding Source',
    fundingSourceHelperText: 'If not listed, please specify.',
    phdResearchTopic: 'PhD Research Topic',
    phdResearchAbstract: 'PhD Research Abstract',
    epistemologicalDisciplines: 'Epistemological Disciplines',
    epistemologicalDisciplinesOther: 'Other Epistemological Discipline',
    researchInterests: 'Research Interests',
    researchInterestsPlaceholder: 'Type and press Enter to add a new research interest',
    methodologicalExpertise: 'Methodological Expertise',
    affiliation: 'Affiliation Institute in Indonesia',
    jobTitle: 'Occupation / Job Title',
    jobTitleOther: 'Other Occupation / Job Title',
    facebook: 'Facebook URL or ID',
    twitter: 'Twitter URL or Username',
    instagram: 'Instagram URL or Username',
    linkedIn: 'LinkedIn URL or ID',
    gitHub: 'GitHub URL or Username',
    researchGate: 'ResearchGate URL or Username',
    orcidID: 'ORCID ID (16 digits)',
    personalBlog: 'Personal Blog URL',
    publicationsHelperText: 'Click "Search" to search by the DOI number or ISBN. Click "Remove" if you don\'t have any scientific publications.',
    publicationsInputType: 'Input Type',
    publicationsManualInputType: 'Manual (Please use Harvard referencing style)',
    popularScienceArticlesHelperText: 'Click "Remove" if you don\'t have any popular science articles.',
    title: 'Title',
    year: 'Year',
    articleURL: 'Article URL',
    communityServicesHelperText: 'Click "Remove" if you don\'t have any community service experiences.',
    speakerExperiencesHelperText: 'Click "Remove" if you don\'t have any speaker experiences.',
    skills: 'Type of skills that you want to expose',
    skillsHelperText: 'For example, "Expert of digital transformation for SMEs" atau "Expert of carbon market development", etc.',
    biography: 'Short Biography',
    opportunities: 'Opportunities for collaboration',
    opportunitiesHelperText: 'Opportunities for collaboration or business to offer or to be advertised. ' +
                            'For example, as a speaker, MoU industry, etc. (This will not be shown on the website)',
    photo: 'Photo',
    photoHelperText: 'Please upload a photo of yourself in portrait orientation and high resolution (max. 10 MB).',
    paymentProofHelperText: 'Please upload a proof of payment for your membership fee (min. £10).',
    registrationDate: 'Registration Date',
  },
  profileFieldsValidationWarnings: {
    membershipType: 'Membership Type is required',
    givenName: 'Given name is required',
    nickname: 'Nickname is required',
    gender: 'Gender is required',
    hasDisability: "Please select. If you prefer not to answer, please select 'Prefer not to answer'.",
    disability: 'If you answered yes, please specify.',
    emailAddress: 'Email address is required',
    validEmailAddress: 'Email must be a valid email address',
    emailAddressNotValid: 'We do not accept this format of email address.',
    secondaryEmailAddress: 'Secondary email is required',
    validSecondaryEmailAddress: 'Secondary email must be a valid email address',
    doctrineEmailExists: 'This Doctrine email address already exists. Please choose another one.',
    doctrineEmailNotExists: 'This Doctrine email address does not exist. Make sure that you put the correct one if you already have one.',
    phoneNumber: 'Phone number is required',
    region: 'Region is required',
    status: 'PhD status is required',
    UKInstitution: 'UK institution is required',
    university: 'University is required',
    otherUniversity: 'Other university is required when you choose "Other" in the university field',
    programmeName: 'Programme name is required',
    phdStartDate: 'PhD start date is required',
    phdEndDate: 'PhD end date is required',
    fundingSource: 'Funding source is required',
    epistemologicalDisciplines: 'Epistemological disciplines is required',
    epistemologicalDisciplinesOther: 'Other epistemological discipline is required',
    researchInterests: 'Research interests is required',
    researchInterestsTypeErrorMessage: 'Press Enter to add you first research interest!',
    methodologicalExpertise: 'Methodological expertise is required',
    academicEmailAddress: 'Academic email address is required',
    validAcademicEmailAddress: 'Academic email domain must match a UK university email',
    universityProfileURL: 'University profile URL is required. Type "N/A" if you don\'t have one.',
    phdResearchTopic: 'PhD research topic is required. Type "N/A" if you don\'t have one.',
    phdResearchAbstract: 'PhD research abstract is required. Type "N/A" if you don\'t have one.',
    facebook: 'Facebook must be a valid URL or ID',
    twitter: 'Twitter must be a valid URL or username',
    instagram: 'Instagram must be a valid URL or username',
    linkedIn: 'LinkedIn must be a valid URL or ID',
    gitHub: 'GitHub must be a valid URL or username',
    researchGate: 'ResearchGate must be a valid URL or username',
    orcidID: 'ORCID ID must be a valid ID',
    inputType: 'Input type is required',
    title: 'Title is required',
    year: 'Year is required',
    photo: 'Profile picture is required',
    paymentProof: 'Membership proof of payment is required',
  },
  genders: {
    female: 'Female',
    male: 'Male',
    other: 'Prefer not to say',
  },
  disabilities: {
    physical: 'Physical Disability',
    intellectual: 'Intellectual Disability',
    mental: 'Mental Disability',
    speaking: 'Speaking Sensory Disability',
    hearing: 'Hearing Sensory Disability',
    visual: 'Visual Sensory Disability',
  },
  status: {
    active: 'Active PhD Student',
    alumni: 'PhD Alumni',
    postdoc: 'Postdoctoral',
    faculty: 'Faculty Member',
    professional: 'Professional',
    other: 'Other',
  },
  fundingSource: {
    other: 'Other',
  },
  methodologicalExpertise: {
    qualitativeStudy: 'Qualitative Study (narrative, visual, etnography, multimodal etnography)',
    quantitativeStudy: 'Quantitative Study',
    mixedMethods: 'Mixed Methods',
  },
  jobTitle: {
    lecturerPNS: 'Lecturer (PNS)',
    lecturerNonPNS: 'Lecturer (Non-PNS)',
    researcher: 'Researcher / Post-Doc',
    consultant: 'Consultant',
    bureaucrat: 'Bureaucrat / Government Official',
    army: 'Army / Police',
    officerPrivate: 'Officer (Private)',
    officerBUMN: 'Officer (BUMN)',
    entrepreneur: 'Entrepreneur',
    other: 'Other',
  },
  preCensusLandingPage: {
    title: 'Welcome to Doctrine UK Census!',
    body: 'Please come back again on 9th of April 2023 to fill in the census.',
  },
  censusL1: {
    title: 'Doctrine UK Census',
    introduction1: 'Welcome to affiliate membership census of Doctrine UK. ',
    introduction2: 'By being an affiliate member, you will be able to access and join our knowledge clusters discussions, ' +
        'WhatsApp Group for Indonesian PhD Student in the UK, and other free facilities (Doctrine summit, seminar, etc).',
    termsAndConditionsTitle: 'Do you agree with the following terms and conditions?',
    termsAndConditionsBody1: 'By submitting this form, you agree that you:',
    termsAndConditionsBody2a: '1. Declare your willingness to send your data and be an affiliate member of Doctrine UK.',
    termsAndConditionsBody2b: '1. Declare your willingness to send your data and be a chartered member of Doctrine UK.',
    termsAndConditionsBody3: 'The decree of Doctrine UK membership status can be accessed through the following link:',
    termsAndConditionsBody4: '2. Agree to the AD/ART of Doctrine UK that can be accessed through the following link:',
    termsAndConditionsBody5: '3. Allow your data to be managed as described in the Tata Kelola Data Doctrine UK document ' +
        'which can be accessed through the following link:',
    termsAndConditionsBody6: 'If you have any questions or difficulties when filling out this census, you can contact us ' +
        'by joining the following WhatsApp group: '
  },
  censusL1Thanks: {
    title: 'Thank you for submitting your census data!',
    body1: 'Your data has been submitted and will be reviewed by Doctrine UK. ' +
        'A confirmation email has been sent to the email address you provided.',
    body2Active: 'You are now an affiliate member of Doctrine UK. If you have any questions, please contact us by joining ' +
        'the following WhatsApp group: ',
    body2Yore: 'You are now a member of yore of Doctrine UK. If you have any questions, please contact us by joining ' +
        'the following WhatsApp group: ',
    subtitleChartered: 'Are you interested in becoming a chartered member of Doctrine UK?',
    chartered1: 'To be a Chartered member, you need to pay the membership fee of a minimum of £10 per year. ' +
        'By registering as a Chartered member, you will receive the following additional facilities: ',
    charteredFac1: 'Doctrine member email (username@doctrineuk.org)',
    charteredFac2: 'Google Drive with a large storage capacity',
    charteredFac3: 'Digital ID Membership Card',
    charteredFac5: 'Opportunities to publish Standard Research Profile on ',
    subtitleYorePremium: 'Are you interested in becoming a Member of Yore Premium of Doctrine UK?',
    yorePremium1: 'To be a Member of Yore Premium, you need to pay the membership fee of a minimum of £20 per year. ' +
        'By registering as a Member of Yore Premium, you will receive the following additional facilities: ',
    yorePremiumFac1: 'Doctrine member email (username@yore.doctrineuk.org)',
    yorePremiumFac2: 'Publish as Top Research Profile on ',
    yorePremiumFac3: 'Top Priority for collaboration service as speaker and Industry MoU',
    yorePremiumFac4: 'Google Drive with a large storage capacity',
    yorePremiumFac5: 'Digital ID Membership Card',
    notInterestedChartered: 'Click the button below to become a chartered member of Doctrine UK!',
    notInterestedYorePremium: 'Click the button below to become a member of yore premium of Doctrine UK!',
    interestButtonChartered: 'Become a chartered member',
    interestButtonYorePremium: 'Become a member of yore premium',
    interested1Chartered: 'Thank you for your interest in becoming a chartered member!',
    interested1YorePremium: 'Thank you for your interest in becoming a member of yore premium!',
    interested2: 'We have sent you an email with more information on how to proceed. Please follow the instructions in the email.'
  },
  censusL2: {
    titleChartered: 'Doctrine UK Census - Chartered Member',
    titleYorePremium: 'Doctrine UK Census - Member of Yore Premium',
    subtitle: 'please fill in the form below to complete your membership application.',
  },
  censusL2Thanks: {
    titleChartered: 'Thank you for registering to be a chartered member!',
    titleYorePremium: 'Thank you for registering to be a member of yore premium!',
    body1: 'Your data has been submitted and your proof of payment will be reviewed by Doctrine UK. ' +
        'A confirmation email has been sent to the email address you provided.',
    body2: 'Please wait for us to contact you with further information. If you have any questions, please contact us by joining ' +
        'the following WhatsApp group: ',
  },
  membershipTypeDialog: {
    title: 'Membership Classifications and Facilities',
    statutory1: 'All on-going PhD students in the UK',
    statutory2: 'No need to register',
    affiliate1: 'All on-going PhD students in the UK who are in the WhatsApp group, members of knowledge clusters, etc. (not required)',
    affiliate2: 'Free',
    affiliate3: 'Take part in Doctrine UK Census',
    chartered1: 'Active members who need Doctrine facilities: email, Google Workspace, Google Drive, Digital ID Membership Card, ' +
        'as well as researcher profile publication on Doctrine UK website',
    chartered2: 'Executive members, Chairman, and Vice Chairman of Knowledge Clusters',
    chartered3: 'Filling in the continuation of census form for the research profile on website.',
    chartered4: 'Contributing by paying at least £10 per year for membership fee',
    memberOfYoreStandard1: 'Open for',
    memberOfYoreStandard1a: 'PhD alumni of UK institutions who are currently in the UK or outside the UK',
    memberOfYoreStandard1b: 'Post-Doctoral or Faculty Member in the UK;',
    memberOfYoreStandard1c: 'or PhD alumni who are currently working in the UK',
    memberOfYoreStandard2: 'Take part in Doctrine UK Census',
    memberOfYorePremium1: 'Open for Member of Yore who need Doctrine facilities: email, Google Workspace, Google Drive, Digital ID Membership Card, ' +
        'as well as researcher profile publication on Doctrine UK website',
    memberOfYorePremium2: 'Filling in the continuation of census form for the research profile on website.',
    memberOfYorePremium3: 'Contributing by paying at least £20 per year for membership fee',
  },
  vote: {
    blurb1: 'Please vote for the candidates that you want in this election from the list below. \n' +
        'You can do this by clicking the plus button next to the candidate\'s name. \n' +
        'You can also remove a candidate from your vote by clicking the minus button next to the candidate\'s name. ',
    blurb2: 'You can select the "Show University and Role" checkbox to see the university and the past and current role(s) of each candidate. \n' +
        'You can sort or filter the candidates by clicking the three dots next to the column name ',
    blurb3: 'Please scroll down until the end of the page to see the "Submit Vote" button. \n' +
        'Once you have submitted, you cannot change your vote. \n',
    chartered: 'Since you are a chartered member, you have 3 votes that you can used. ' +
        'This can be used to vote for different candidates, or you can use them for the same candidates. \n',
    totalNumCandidates: 'Total number of candidates',
    maxVotes: 'Maximum Votes',
    usedVotes: 'Used Votes',
    availableVotes: 'Available Votes',
    checkbox: 'Show University and Role',
    alreadyVoted: "You have already used your votes in this election.",
    name: 'Name',
    university: 'University',
    role: 'Role',
    thanks: 'Thank you for voting!',
    end: 'The voting period has ended.',
  },
  voteSummaryDialog: {
    title: 'Summary of Votes',
    description: 'You are going to vote for the following candidates:',
    warning: "Please make sure that you have voted for the correct candidates. You can't change your vote after you submit it.",
  },
  buttons: {
    'submit': 'Submit',
    'search': 'Search',
    'remove': 'Remove',
    'addItem': 'Add Item',
    'saveChanges': 'Save Changes',
    'openMembershipTypes': 'Click to see the different membership types!',
    'openUKRegionsMap': 'Open UK Regions Map',
    'agree': 'Agree',
    'disagree': 'Disagree',
    'submitVotes': 'Submit Votes',
  },
  booleanOptions: {
    yes: 'Yes',
    no: 'No',
    preferNotToSay: 'Prefer not to say',
  },
  manageMembership: {
    startDate: 'Start Date',
    endDate: 'End Date',
    status: 'Status',
    renewMembership: 'Renew Membership',
    renewQuestion: 'Do you want to renew your membership?',
    renewDesc1: 'If you want to renew your membership for another year, please make a payment of minimum £10 (for chartered member) or ' +
        '£20 (for member of yore premium) to the following bank account:',
    renewDesc2: 'Name: Doctrine UK',
    renewDesc3: ' and then upload the proof of payment below.',
  }
};

export default en;
